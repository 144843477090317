import { createAsyncThunk } from '@reduxjs/toolkit'
import { gameCloseService, loginService } from 'network/services/auth.service'
import { ROUTE_PATHS } from 'constants/index'
import { signIn, signOut } from 'helpers/cookie.helpers'

export const userLoginThunk = createAsyncThunk('user/login', async ({ payload, history }, thunkApi) => {
  try {
    const res = await loginService(payload)
    if (res) {
      signIn({ token: res?.accessToken })
      // history.replace({ pathName: ROUTE_PATHS.shanKoeMeeLobby })
    }
    return res
  } catch (error) {
    history.push(ROUTE_PATHS.error, { errorMsg: 'unauthorizedAccessOrSomethingWentWrong' })
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const gameCloseThunk = createAsyncThunk('user/game-close', async (data, thunkApi) => {
  try {
    const res = await gameCloseService()
    if (res) {
      signOut()
    }
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})
