import axiosInstanceService from 'network/apis/index'
import { microServices } from 'network/apis/microservice/index'
import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'constants/index'

export const getShanGameSettingsService = () => {
  return axiosInstanceService(METHOD_TYPES.get, 'shan-game/setting', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table
  })
}
