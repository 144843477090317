/* ==========================================================================
  Languages
========================================================================== */
export const LANGUAGES = [
  { label: 'English', languageCode: 'en' },
  { label: 'Myanmar', languageCode: 'my' }
]

/* ==========================================================================
  HTTP Method Types
========================================================================== */
export const METHOD_TYPES = {
  get: 'get',
  post: 'post',
  put: 'put',
  delete: 'delete',
  patch: 'patch'
}

/* ==========================================================================
LocalStorage / Cookie data
========================================================================== */
export const TOKEN = 'user_auth_token'
export const LANGUAGE = 'user_language'

/* ==========================================================================
  Loader Types
========================================================================== */
export const LOADER_HANDLER_TYPES = {
  page: 'pageLoader',
  submit: 'submitButtonLoader',
  table: 'tableLoader'
}

/* ==========================================================================
  All the navigation route Paths
========================================================================== */
export const PARENT_ROUTES = {
  shanKoeMee: '/shan-koe-mee'
}

export const ROUTE_PATHS = {
  // ----single routes------------
  home: '/',
  shanKoeMeeLobby: `${PARENT_ROUTES.shanKoeMee}`,
  dealerAppletHome: `${PARENT_ROUTES.shanKoeMee}/dealer-applet`,
  shanKoeMeeGameTable: `${PARENT_ROUTES.shanKoeMee}/:tournamentId`,
  dealerAppletGameTable: `${PARENT_ROUTES.shanKoeMee}/dearler-applet/play`,
  error: '/error'
}

/* ==========================================================================
  Game Ids
========================================================================== */
export const DEFAULT_GAME_IDS = {
  SHAN_KOE_MEE: 1
}

/* ==========================================================================
  Card Deck
========================================================================== */
export const CARD_DECK = {
  1: { id: 1, suit: 'Club', suitRank: 1, description: 'Two', shortString: 'C2', sort: 2, value: 2 },
  2: { id: 2, suit: 'Club', suitRank: 1, description: 'Three', shortString: 'C3', sort: 3, value: 3 },
  3: { id: 3, suit: 'Club', suitRank: 1, description: 'Four', shortString: 'C4', sort: 4, value: 4 },
  4: { id: 4, suit: 'Club', suitRank: 1, description: 'Five', shortString: 'C5', sort: 5, value: 5 },
  5: { id: 5, suit: 'Club', suitRank: 1, description: 'Six', shortString: 'C6', sort: 6, value: 6 },
  6: { id: 6, suit: 'Club', suitRank: 1, description: 'Seven', shortString: 'C7', sort: 7, value: 7 },
  7: { id: 7, suit: 'Club', suitRank: 1, description: 'Eight', shortString: 'C8', sort: 8, value: 8 },
  8: { id: 8, suit: 'Club', suitRank: 1, description: 'Nine', shortString: 'C9', sort: 9, value: 9 },
  9: { id: 9, suit: 'Club', suitRank: 1, description: 'Ten', shortString: 'C10', sort: 10, value: 0 },
  10: { id: 10, suit: 'Club', suitRank: 1, description: 'Jack', shortString: 'CJ', sort: 11, value: 0 },
  11: { id: 11, suit: 'Club', suitRank: 1, description: 'Queen', shortString: 'CQ', sort: 12, value: 0 },
  12: { id: 12, suit: 'Club', suitRank: 1, description: 'King', shortString: 'CK', sort: 13, value: 0 },
  13: { id: 13, suit: 'Club', suitRank: 1, description: 'Ace', shortString: 'CA', sort: 14, value: 1 },

  14: { id: 14, suit: 'Diamond', suitRank: 2, description: 'Two', shortString: 'D2', sort: 2, value: 2 },
  15: { id: 15, suit: 'Diamond', suitRank: 2, description: 'Three', shortString: 'D3', sort: 3, value: 3 },
  16: { id: 16, suit: 'Diamond', suitRank: 2, description: 'Four', shortString: 'D4', sort: 4, value: 4 },
  17: { id: 17, suit: 'Diamond', suitRank: 2, description: 'Five', shortString: 'D5', sort: 5, value: 5 },
  18: { id: 18, suit: 'Diamond', suitRank: 2, description: 'Six', shortString: 'D6', sort: 6, value: 6 },
  19: { id: 19, suit: 'Diamond', suitRank: 2, description: 'Seven', shortString: 'D7', sort: 7, value: 7 },
  20: { id: 20, suit: 'Diamond', suitRank: 2, description: 'Eight', shortString: 'D8', sort: 8, value: 8 },
  21: { id: 21, suit: 'Diamond', suitRank: 2, description: 'Nine', shortString: 'D9', sort: 9, value: 9 },
  22: { id: 22, suit: 'Diamond', suitRank: 2, description: 'Ten', shortString: 'D10', sort: 10, value: 0 },
  23: { id: 23, suit: 'Diamond', suitRank: 2, description: 'Jack', shortString: 'DJ', sort: 11, value: 0 },
  24: { id: 24, suit: 'Diamond', suitRank: 2, description: 'Queen', shortString: 'DQ', sort: 12, value: 0 },
  25: { id: 25, suit: 'Diamond', suitRank: 2, description: 'King', shortString: 'DK', sort: 13, value: 0 },
  26: { id: 26, suit: 'Diamond', suitRank: 2, description: 'Ace', shortString: 'DA', sort: 14, value: 1 },

  27: { id: 27, suit: 'Heart', suitRank: 3, description: 'Two', shortString: 'H2', sort: 2, value: 2 },
  28: { id: 28, suit: 'Heart', suitRank: 3, description: 'Three', shortString: 'H3', sort: 3, value: 3 },
  29: { id: 29, suit: 'Heart', suitRank: 3, description: 'Four', shortString: 'H4', sort: 4, value: 4 },
  30: { id: 30, suit: 'Heart', suitRank: 3, description: 'Five', shortString: 'H5', sort: 5, value: 5 },
  31: { id: 31, suit: 'Heart', suitRank: 3, description: 'Six', shortString: 'H6', sort: 6, value: 6 },
  32: { id: 32, suit: 'Heart', suitRank: 3, description: 'Seven', shortString: 'H7', sort: 7, value: 7 },
  33: { id: 33, suit: 'Heart', suitRank: 3, description: 'Eight', shortString: 'H8', sort: 8, value: 8 },
  34: { id: 34, suit: 'Heart', suitRank: 3, description: 'Nine', shortString: 'H9', sort: 9, value: 9 },
  35: { id: 35, suit: 'Heart', suitRank: 3, description: 'Ten', shortString: 'H10', sort: 10, value: 0 },
  36: { id: 36, suit: 'Heart', suitRank: 3, description: 'Jack', shortString: 'HJ', sort: 11, value: 0 },
  37: { id: 37, suit: 'Heart', suitRank: 3, description: 'Queen', shortString: 'HQ', sort: 12, value: 0 },
  38: { id: 38, suit: 'Heart', suitRank: 3, description: 'King', shortString: 'HK', sort: 13, value: 0 },
  39: { id: 39, suit: 'Heart', suitRank: 3, description: 'Ace', shortString: 'HA', sort: 14, value: 1 },

  40: { id: 40, suit: 'Spade', suitRank: 4, description: 'Two', shortString: 'S2', sort: 2, value: 2 },
  41: { id: 41, suit: 'Spade', suitRank: 4, description: 'Three', shortString: 'S3', sort: 3, value: 3 },
  42: { id: 42, suit: 'Spade', suitRank: 4, description: 'Four', shortString: 'S4', sort: 4, value: 4 },
  43: { id: 43, suit: 'Spade', suitRank: 4, description: 'Five', shortString: 'S5', sort: 5, value: 5 },
  44: { id: 44, suit: 'Spade', suitRank: 4, description: 'Six', shortString: 'S6', sort: 6, value: 6 },
  45: { id: 45, suit: 'Spade', suitRank: 4, description: 'Seven', shortString: 'S7', sort: 7, value: 7 },
  46: { id: 46, suit: 'Spade', suitRank: 4, description: 'Eight', shortString: 'S8', sort: 8, value: 8 },
  47: { id: 47, suit: 'Spade', suitRank: 4, description: 'Nine', shortString: 'S9', sort: 9, value: 9 },
  48: { id: 48, suit: 'Spade', suitRank: 4, description: 'Ten', shortString: 'S10', sort: 10, value: 0 },
  49: { id: 49, suit: 'Spade', suitRank: 4, description: 'Jack', shortString: 'SJ', sort: 11, value: 0 },
  50: { id: 50, suit: 'Spade', suitRank: 4, description: 'Queen', shortString: 'SQ', sort: 12, value: 0 },
  51: { id: 51, suit: 'Spade', suitRank: 4, description: 'King', shortString: 'SK', sort: 13, value: 0 },
  52: { id: 52, suit: 'Spade', suitRank: 4, description: 'Ace', shortString: 'SA', sort: 14, value: 1 }
}

/* ==========================================================================
  Game Result
========================================================================== */
export const SHAN_GAME_BET_RESULT = {
  WON: 'won',
  LOST: 'lost'
}

/* ==========================================================================
  Card Actions
========================================================================== */
export const SHAN_GAME_CARD_ACTION = {
  NO_ACTION: 0,
  DRAW: 1,
  HOLD: 2,
  AUTO_SHAN: 3,
  BANKER_CATCH: 4,
  BANKER_CATCH_AND_DRAW: 5,
  BANKER_CATCH_AND_HOLD: 6
}

/* ==========================================================================
  Shan Game States
========================================================================== */
export const SHAN_GAME_ROUND_STATE = {
  STARTED: 1,
  BETTING_FINISHED: 2,
  CARD_DISTRIBUTION_FINISHED: 3,
  PLAYER_CARD_TIMER_FINISHED: 4,
  BANKER_CARD_TIMER_FINISHED: 5,
  SHOW_RESULTS: 6,
  BANKER_SECOND_CARD_TIMER_START: 7,
  BANKER_SECOND_CARD_TIMER_FINISHED: 8,
  SHOW_SECOND_RESULTS: 9,
  STOPPED: 10
}

/* ==========================================================================
  Table column / cell types
========================================================================== */
export const TABLE_CELL_TYPE = {
  status: 'status',
  switch: 'switch',
  button: 'button',
  date: 'date',
  disable: 'disable',
  default: 'default'
}

/* ==========================================================================
  Filter Checkbox Keys
========================================================================== */
export const FOOTER_FILTERS_KEY = {
  OPEN: 'open',
  WAITING: 'waiting',
  RUNNING: 'running',
  FULL: 'full'
}

/* ==========================================================================
  Shan Game Time Handler
========================================================================== */
export const SHAN_GAME_TIME_HANDLER = {
  ROUND_START_COUNTDOWN_TIMER: 11,
  BET_START_TIMER: 13,
  PLAYER_CARD_TIMER: 18,
  BANKER_CARD_TIMER: 18
}

export const TABLE_RANGE = ['low', 'medium', 'high']
