import { createAsyncThunk } from '@reduxjs/toolkit'
import { getShanGameSettingsService } from 'network/services/shanSettings.service'

export const fetchGameSettingsService = createAsyncThunk('shanGame/settings', async (_, thunkApi) => {
  try {
    const res = await getShanGameSettingsService()
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})
