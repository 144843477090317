import { createSlice } from '@reduxjs/toolkit'
import { fetchGameSettingsService } from 'redux-store/thunk/shanSettings.thunk'

const initialState = {
  settings: null
}

const shanSettingsSlice = createSlice({
  name: 'shanSettingsSlice',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchGameSettingsService.fulfilled, (state, action) => {
        return {
          ...state,
          settings: action.payload
        }
      })
  }
})

export default shanSettingsSlice.reducer
