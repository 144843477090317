import React from 'react'
import './index.scss'
import PropTypes from 'prop-types'
import CustomTypography from '../Typography/index'
import { TABLE_RANGE } from 'constants/index'
import { useTranslation } from 'react-i18next'

const CustomTabs = ({ tabsDataList, activeTabIndex, handleTabClick }) => {
  const { t } = useTranslation()
  return (
    <ul className='nav' id='myTab' role='tablist'>
      {
      tabsDataList && TABLE_RANGE.map((tabName, index) => {
        return (
          <li className={`nav-item ${index === activeTabIndex ? 'active' : ''}`} role='presentation' key={tabName}>
            <button
              className={`btn tab-btn tab-button ${index === activeTabIndex ? 'tab-btn-active' : ''}`}
              id='home-tab'
              data-bs-toggle='tab'
              data-bs-target='#home'
              type='button'
              role='tab'
              aria-controls='home'
              aria-selected='true'
              onClick={() => handleTabClick(index)}
            >{tabName !== 'high'
              ? (
                <>
                  <CustomTypography fontWeightClass='fw-400' fontFamilyClass='arial-condensed-bold' textTransform='text-capitalize'>{t(tabName)}</CustomTypography>
                  {tabsDataList?.[tabName] && <CustomTypography fontFamilyClass='arial-condensed-bold'>{`(${tabsDataList?.[tabName]?.[0]} - ${tabsDataList?.[tabName]?.[1]})`}</CustomTypography>}
                </>)
              : (
                <>
                  <CustomTypography fontWeightClass='fw-400' fontFamilyClass='arial-condensed-bold' textTransform='text-capitalize'>{t(tabName)}</CustomTypography>
                  {tabsDataList?.[tabName] && <CustomTypography fontFamilyClass='arial-condensed-bold'>{`(${tabsDataList?.[tabName]} & ${t('above')})`}</CustomTypography>}
                </>)}
            </button>
          </li>
        )
      })
      }
    </ul>
  )
}

export default CustomTabs

CustomTabs.defaultProps = {
  tabsDataList: {},
  activeTabIndex: 0,
  handleTabClick: () => ({})
}

CustomTabs.propTypes = {
  tabsDataList: PropTypes.object.isRequired,
  activeTabIndex: PropTypes.number.isRequired,
  handleTabClick: PropTypes.func.isRequired
}
