import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import * as locales from './locales/index'
import { LANGUAGES } from 'constants/index'
import { getLocalLanguage } from 'helpers/localstorage.helpers'
console.log(getLocalLanguage())
i18n.use(initReactI18next).init({
  resources: {
    ...Object.entries(locales).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: {
          translation: value
        }
      }),
      {}
    )
  },
  lng: getLocalLanguage() || LANGUAGES[1].languageCode,
  fallbackLng: LANGUAGES[1].languageCode
})

export default i18n
