import React, { useEffect } from 'react'
import './index.scss'
import { BackArrowIcon } from 'components/ui-kit/Icons/iconComponents/BackArrowIcon'
import CustomTypography from 'components/ui-kit/Typography/index'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { ROUTE_PATHS } from 'constants/index'
import { actionSound } from 'utils/audio.utils'

const ShanGameInPlayHeader = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const { tournamentDetails, isGameLeaveTableBtnEnabled } = useSelector(state => state.shanGame)
  const { gameHeaderUniqueId } = useSelector(state => state.header)
  const { userInfo } = useSelector(state => state.auth)

  const handleBackNavigation = () => {
    actionSound.play()
    const operator = sessionStorage.getItem('operatorId')
    const token = sessionStorage.getItem('playerToken')
    const currency = sessionStorage.getItem('currencyCode')

    history.replace(`${ROUTE_PATHS.shanKoeMeeLobby}?operator=${operator}&token=${token}&currency=${currency}`)
  }

  useEffect(() => {
    // window.addEventListener('resize', () => window.location.reload())
    // return () => window.removeEventListener('resize', () => { })
  }, [])

  return (
    <div className='tournament-info'>
      <div className='d-inline-flex align-items-start'>
        <BackArrowIcon
          className={`${isGameLeaveTableBtnEnabled ? '' : 'disable-icon'}`}
          style={{ cursor: isGameLeaveTableBtnEnabled ? 'pointer' : 'unset' }}
          onClick={() => {
            isGameLeaveTableBtnEnabled && handleBackNavigation()
          }}
        />
        <CustomTypography fontSizeClass='fs-0x7' otherStyleClasses='mt-4'>
          {gameHeaderUniqueId || ''}
        </CustomTypography>
      </div>
      {tournamentDetails && (
        <div className='float-right d-flex mt-4 mr-10'>
          <CustomTypography fontSizeClass='fs-0x7' otherStyleClasses='mr-10'>
            {t('username') + ': ' + (userInfo?.user?.userName || 'User')}
          </CustomTypography>
          <CustomTypography fontSizeClass='fs-0x7' otherStyleClasses='mr-10'>
            {t('tournament') + ': ' + (tournamentDetails?.name || '-----')}
          </CustomTypography>
          <CustomTypography fontSizeClass='fs-0x7' otherStyleClasses='mr-10'>
            {t('banker') + ': ' + (tournamentDetails?.bankerAmount || '--')}
          </CustomTypography>
          <CustomTypography fontSizeClass='fs-0x7' otherStyleClasses='mr-10'>
            {t('headerBuyIn') + ': ' + `${tournamentDetails?.minBuyIn || '--'}/${tournamentDetails?.maxBuyIn || '--'}`}
          </CustomTypography>
        </div>
      )}
    </div>
  )
}

export default ShanGameInPlayHeader
