import { manager } from 'helpers/socket.helpers'

const createSocketConnection = (connectionUrl, authToken) => {
  const ioConnection = manager.socket(connectionUrl, {
    ...(authToken
      ? {
          auth: {
            authorization: `Bearer ${authToken}`
          }
        }
      : {})
  })

  return ioConnection
}

export default createSocketConnection
