import axiosInstanceService from 'network/apis/index'
import { microServices } from 'network/apis/microservice/index'
import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'constants/index'
import successMessages from 'network/messages/successMessages'

export const loginService = (data) => {
  return axiosInstanceService(METHOD_TYPES.post, 'user/login', data, {
    server: microServices.USER_URL,
    handlerEnabled: false,
    loader: null
    // successMessage: successMessages.login
  })
}

export const gameCloseService = () => {
  return axiosInstanceService(METHOD_TYPES.post, 'user/game-close', {}, {
    server: microServices.USER_URL,
    handlerEnabled: false,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: successMessages.gameClose
  })
}
