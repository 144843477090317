import React from 'react'
import './index.scss'
import PropTypes from 'prop-types'
import Header from '../Header/index'
import Footer from '../Footer/index'

const MainLayout = ({ headerVariant, footerVariant, children, isHeader, isFooter, wantContainerStyle }) => {
  return (
    <div {...(wantContainerStyle && { className: 'pt-3 root-container h-100 d-flex flex-column justify-content-between' })}>
      <div>
        {isHeader ? <Header variant={headerVariant} /> : <></>}
        {children}
      </div>
      {isFooter ? <Footer variant={footerVariant} /> : <></>}
    </div>
  )
}

export default MainLayout

MainLayout.defaultProps = {
  headerVariant: 'default',
  footerVariant: 'default',
  isHeader: true,
  isFooter: true,
  wantContainerStyle: true
}

MainLayout.propTypes = {
  headerVariant: PropTypes.oneOf(['default', 'shanGameInPlay']),
  footerVariant: PropTypes.oneOf(['default', 'shanGameInPlay']),
  isHeader: PropTypes.bool,
  isFooter: PropTypes.bool,
  wantContainerStyle: PropTypes.bool
}
