export default {
  // Currency Translation
  USD: '$',
  GBP: '£',
  INR: '₹',
  EUR: '€',

  // Form Validation Translations
  mustbeAtleast3Characters: '{{label}} အနည်းဆုံးဖြစ်ရမည်။ {{minNumber}} ဇာတ်ကောင်များ',
  cannotExceed32Characters: '{{label}} မကျော်လွန်နိုင်ပါ။ {{maxNumber}} ဇာတ်ကောင်များ',
  cannotExceed15Characters: '{{label}} မကျော်လွန်နိုင်ပါ။ {{maxNumber}} ဇာတ်ကောင်များ',
  isRequired: '{{label}} လိုအပ်ပါသည်',
  invalidEmailFormat: 'မမှန်ကန်သော အီးမေးလ်ဖော်မတ်',

  // Success Message Translations
  loggedInSuccess: 'အကောင့်ဝင်ပြီးပါပြီ။',

  // Error Message Translations
  loggedOutSuccess: 'အောင်မြင်စွာ အကောင့်ထွက်ပြီးပါပြီ။',
  internalServerError: 'အတွင်းဆာဗာမှားယွင်းမှု',
  usernameOrEmailNotMatch: 'အသုံးပြုသူအမည်/အီးမေးလ် သို့မဟုတ် စကားဝှက်နှင့် မကိုက်ညီပါ။',
  userAlreadyExists: 'အသုံးပြုသူရှိပြီးသား',
  userNotExists: 'အသုံးပြုသူမရှိပါ။',
  unAuthorized: 'အခွင့်မရှိဘဲ',
  operatorPlayerUnauthenticated: 'အော်ပရေတာ ပလေယာ အထောက်အထား မခိုင်လုံ',

  // Other Translations
  404: '၄၀၄',
  change: 'ပြောင်းလဲပါ။',
  login: 'လော့ဂ်အင်',
  pageNotFound: 'သင်ရှာနေသည့် စာမျက်နှာကို ကျွန်ုပ်တို့ ရှာမတွေ့ပါ။',
  error: 'အမှား',
  somethingWentWrong: 'တစ်ခုခုမှားသွားသည်။',
  noData: 'ဒေတာမရရှိနိုင်ပါ။',
  transactions: 'ငွေပေးငွေယူ',
  settings: 'ဆက်တင်များ',
  gameRules: 'ဂိမ်းစည်းမျဉ်းများ',
  loading: 'ဒိန်း',
  unauthorizedAccessOrSomethingWentWrong: 'ခွင့်ပြုချက်မရှိဘဲ ဝင်ရောက်ခြင်း သို့မဟုတ် တစ်စုံတစ်ခု မှားယွင်းသွားပါသည်။',
  sessionExpired: 'စက်ရှင် သက်တမ်းကုန်သွားပါပြီ။',
  newLoginDetected: 'အမှားအယွင်း- လော့ဂ်အင်အသစ်ကို တွေ့ရှိခဲ့သည် (ကစားသမားသည် တစ်ကြိမ်လျှင် စက်ပစ္စည်းတစ်ခုတွင် ဂိမ်းကိုသာ ဝင်ရောက်ကြည့်ရှုနိုင်သည်။ ယခင်စက်ပစ္စည်းမှ ထွက်ရန် ထပ်မံ၍ အကောင့်ဝင်ပါ)',
  tokenSessionExpired: 'အမှားအယွင်း- သင့်စက်ရှင်သက်တမ်းကုန်သွားပါပြီ၊ ဂိမ်းကိုဆက်လက်ကစားရန် ထပ်မံဝင်ရောက်ပါ။',
  notKnown: 'အမှားအယွင်း- မသိပါ။',
  invalidToken: 'အမှား- မမှန်ကန်သော တိုကင်။ ',
  disconnect: 'အဆက်ဖြတ်ပါ။',

  tournamentName: 'ပြိုင်ပွဲအမည်',
  banker: 'ဒိုင်',
  buyIn: 'ဝိုင်းကြေး',
  betLimit: 'ထိုးကြေးကန့်သတ်ချက်',
  players: 'ထိုးသား',
  action: 'အက်ရှင်',
  full: 'လူပြည့်နေသောဝိုင်းများ',
  prev: 'အကြို',
  next: 'နောက်တစ်ခု',
  auto: 'ဒို',
  win: 'နိုင်သည်',
  lose: 'ရှုံးသည်',
  noRecordsFound: 'မှတ်တမ်းများ မတွေ့ပါ။',

  pointLabel: 'အမှတ်ယူရမည့်အပိုင်း',
  minimumAmount: 'အနည်းဆုံးပမာဏ',
  maximumAmount: 'အများဆုံးပမာဏ',
  autoRebuy: 'အလိုအလျောက်ပြန်ဝယ်ခြင်း (သင်၏ဝယ်ယူမှုပမာဏသို့)',
  minimumBuyIn: 'အနည်းဆုံး BuyIn',
  maximumBuyIn: 'အများဆုံး BuyIn',
  ok: 'အိုကေ',
  cancel: 'မလုပ်တော့',
  open: 'ဝိုင်းလွတ်များ',
  waiting: 'ထိုးသားစောင့်နေသောဝိုင်းများ',
  running: 'ဆော့နေသောဝိုင်းများ',
  hold: 'တော်ပြီ',
  draw: 'ဆွဲသည်။',
  table: 'စားပွဲ',
  tournament: 'ပြိုင်ပွဲ',
  connectionLostPleaseAgainJoinByLobbyToPlayTournament: 'သည်းခံပါ ချိတ်ဆက်မှု ပြတ်တောက်သွားသည်။ ',
  backToLobby: 'ဧည့်ခန်း သို့ ပြန်သွားရန်',
  sitHere: 'ထိုင်မည်',
  submit: 'တင်ပြပါ။',
  chatWarning: 'ရိုင်းစိုင်းသောစကားများပြောသည်ဟု တိုင်ကြားခံရပါက အကောင့်ပိတ်သိမ်းပါမည်',
  betAmountMinimum: 'အနည်းဆုံးထိုးရန်',
  betAmountMaximum: 'အများဆုံးထိုးရန်',
  betModalOkayButtonLabel: 'အိုကေ',
  betModalMaxButtonLabel: 'အများဆုံးထိုးရန်',
  headerBuyIn: 'ဝယ်ယူမှု',
  username: 'အသုံးပြုသူအမည်"',
  doneActionButton: 'ပြီးပြီ။',
  drawActionButton: 'ဆွဲမယ်',
  goodActionButton: 'တော်ပြီ',
  catchActionButton: 'ဖဲဖမ်းသည်',
  toRubStain: 'ဖဲပွတ်ရန်',
  gameInitiatesIn: 'ဂိမ်းစတင်သည်။ {{time}} အဆက်ဖြတ်ပါ။',
  yourGameWillStartIn: 'ကစားပွဲစတင်ရန် {{time}} စက္ကန့်အလို',
  takeSeatDueToBankerWarningError: 'လက်ရှိထိုးသားများ၏ unitsဖြင့် warnings ခေါ်နေပါသဖြင့် အလှ ည့်ပီးမှ ထိုင်ပါရန်',
  welcome: 'ကြိုဆိုပါတယ်။',
  balance: 'လက်ကျန်',
  above: 'အထက်',
  low: 'ဝိုင်းသေး',
  medium: 'ဝိုင်းလတ်',
  high: 'ဝိုင်းကြီး',

  maintenanceMode: 'ပြုပြင်ထိန်းသိမ်းမှုမုဒ်',
  maintenanceModeMessage: 'ဂိမ်းကို ပြုပြင်ထိန်းသိမ်းနေပါသည်၊ အချိန်အနည်းငယ်ကြာပြီးနောက် ကျွန်ုပ်တို့နှင့် ပူးပေါင်းပါ။',
  roundFailedError: 'နည်းပညာဆိုင်ရာ ပြဿနာအချို့ကြောင့် ဤအဝိုင်းကို ဖျက်သိမ်းလိုက်သည်၊ သင်၏ ပမာဏအပြည့်အစုံကို မကြာမီ ပြန်လည်ပေးအပ်ပါမည်။',

  //Dealers Appler
  place: "နေရာ",
  third: "၃",
  card: "ကတ်",
  cards: "ကတ်များ",
  declare: "ကြေငြာပါ။",
  result: "ရလဒ်",
  show: "ရှိုး",
  rake: "ထွန်ခြစ်",
  minimumBet: "အနည်းဆုံးလောင်းကြေး",
  amount: "ပမာဏ",
  selected: "ရွေးချယ်ခဲ့သည်။",
  select: "ရွေးပါ။",
  bet: "လောင်းကစား",
  save: "သိမ်းဆည်းပါ။",
  enter: "ဝင်ပါ။",
  password: "စကားဝှက်",
  details: "အသေးစိတ်",
  revenue: "ဝင်ငွေ",
  player: "ဘာညာ",
  reset: 'ပြန်လည်သတ်မှတ်ပါ။',
  goBackToTouranmentSettings: "ပြိုင်ပွဲဆက်တင်များသို့ ပြန်သွားပါ။",

  DEALERS_APPLET: 'အရောင်းကိုယ်စားလှယ် applet',
  HAND_ID: "လက် ID",
  PLAYER: "ကစားသမား",
  USERNAME: "အသုံးပြုသူအမည်",
  BUY_IN: "ဝယ်ပါ။",
  BUY_OUT: "ဝယ်ပါ။",
  BET: "လောင်းကစား",
  WIN: "အနိုင်ရ",
  CARD: "ကတ်",
  SCORE: "နိုင်ပြီ",
  numberOfSeats: "ထိုင်ခုံနံပါတ် (၆)၊",
  enterBetAmtForPlayer: "ကစားသမားအတွက် လောင်းကြေးပမာဏကို ထည့်ပါ။"
}
