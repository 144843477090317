import React from 'react'
import './index.scss'
import { ChatIconIcon } from 'components/ui-kit/Icons/iconComponents/ChatIconIcon'

const GameInPlayFooter = () => {
  return (
    <div>
      <ChatIconIcon />
    </div>
  )
}

export default GameInPlayFooter
