import { Howl } from 'howler'

export const winSound = new Howl({ src: ['/assets/audios/win-sound.mp3'], loop: false, preload: true, volume: 1, html5: false })
export const beepSound = new Howl({ src: ['/assets/audios/beep-sound.mp3'], loop: false, preload: true, volume: 1, html5: false })
export const actionSound = new Howl({ src: ['./../assets/audios/action.mp3'], loop: false, preload: true, volume: 1, html5: false })
export const timerSound = new Howl({ src: ['/assets/audios/timer-sound.mp3'], loop: false, preload: true, volume: 1, html5: false })
export const cardDistributeSound = new Howl({ src: ['/assets/audios/card-distribute.mp3'], loop: false, preload: true, volume: 1, html5: false })

export const muteAllSounds = () => {
  winSound.mute(true)
  beepSound.mute(true)
  actionSound.mute(true)
  timerSound.mute(true)
  cardDistributeSound.mute(true)
}

export const unmuteAllSounds = () => {
  winSound.mute(false)
  beepSound.mute(false)
  actionSound.mute(false)
  timerSound.mute(false)
  cardDistributeSound.mute(false)
}
