import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import NAMESPACES from 'socket-resources/namespaces/index'
import { getAuthToken } from 'helpers/cookie.helpers'
import { WALLET_UPDATE_EVENT } from 'socket-resources/event-constants/wallet/index'
import createSocketConnection from 'socket-resources/socket-connection/index'

let walletSocketInstance

const useWalletSocketNameSpace = () => {
  const { userInfo } = useSelector(state => state.auth)

  useEffect(() => {
    if (userInfo && !walletSocketInstance) {
      const authToken = getAuthToken()
      walletSocketInstance = createSocketConnection(NAMESPACES.WALLET, authToken)
    }
  }, [userInfo])

  const listenWalletUpdateEvent = (cb) => {
    if (walletSocketInstance && cb) {
      walletSocketInstance?.on(WALLET_UPDATE_EVENT, cb)
      return () => {
        walletSocketInstance.off(WALLET_UPDATE_EVENT, cb)
      }
    }
  }

  return { listenWalletUpdateEvent }
}

export default useWalletSocketNameSpace
