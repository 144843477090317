import Routes from 'routes/index'
import React, { Suspense, useEffect } from 'react'
import Loader from 'components/ui-kit/Loader/index'
import 'react-toastify/dist/ReactToastify.css'
import { LOADER_HANDLER_TYPES } from 'constants/index'
import { useSelector, useDispatch } from 'react-redux'
import ErrorBoundary from 'components/layout/ErrorBoundary/index'
import { ToastContainer } from 'react-toastify'
import useWalletSocketNameSpace from 'socket-resources/hooks/useWalletSocketNameSpace'
import { updateWalletBalance } from 'redux-store/redux/slices/auth.slice'
import LoaderPage from 'components/molecules/Loader/index'
import { muteAllSounds, unmuteAllSounds } from 'utils/audio.utils'

function App () {
  const dispatch = useDispatch()

  const pageLoading = useSelector(state => state.loader[LOADER_HANDLER_TYPES.page])

  const { userInfo } = useSelector(state => state.auth)

  const { listenWalletUpdateEvent } = useWalletSocketNameSpace()

  const handleWalletUpdate = ({ data }) => {
    data?.balance && dispatch(updateWalletBalance(data.balance))
  }

  const toggleSounds = () => {
    document.visibilityState === 'visible' ? unmuteAllSounds() : muteAllSounds()
  }

  useEffect(() => {
    document.addEventListener('visibilitychange', toggleSounds)
  }, [])

  useEffect(() => {
    if (userInfo) {
      const cleaner = listenWalletUpdateEvent(handleWalletUpdate)
      return () => cleaner()
    }
  }, [userInfo])

  return (
    <Suspense fallback={
      <LoaderPage />
    }
    >
      <ErrorBoundary>
        <ToastContainer
          position='top-right'
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme='colored'
        />
        {
          pageLoading && (
            <div className='page-loader'>
              <Loader />
            </div>
          )
        }
        <Routes />
      </ErrorBoundary>
    </Suspense>

  )
}

export default App
