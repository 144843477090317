import { createSlice } from '@reduxjs/toolkit'
import { FOOTER_FILTERS_KEY } from 'constants/index'

const defaultLoadersState = {
  [FOOTER_FILTERS_KEY.OPEN]: false,
  [FOOTER_FILTERS_KEY.WAITING]: false,
  [FOOTER_FILTERS_KEY.RUNNING]: false,
  [FOOTER_FILTERS_KEY.FULL]: false
}

const footerFiltersSlice = createSlice({
  name: 'footerFiltersSlice',
  initialState: defaultLoadersState,
  reducers: {
    setFooterFilter: (state, action) => {
      return {
        ...state,
        [action.payload]: !state[[action.payload]]
      }
    }
  }
})

export const { setFooterFilter } = footerFiltersSlice.actions

export default footerFiltersSlice.reducer
