import React, { Suspense } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import CustomRoutes from './routes'
import LoaderPage from 'components/molecules/Loader/index'
// import Loader from 'components/ui-kit/Loader/index'

const Routes = () => (
  <Router>
    <Suspense fallback={<LoaderPage />}>
      <CustomRoutes />
    </Suspense>
  </Router>
)

export default Routes
