export default {
  // Currency Translation
  USD: '$',
  GBP: '£',
  INR: '₹',
  EUR: '€',

  // Form Validation Translations
  mustbeAtleast3Characters: '{{label}} must be atleast {{minNumber}} characters',
  cannotExceed32Characters: '{{label}} cannot exceed {{maxNumber}} characters',
  cannotExceed15Characters: '{{label}} cannot exceed {{maxNumber}} characters',
  isRequired: '{{label}} is required',
  invalidEmailFormat: 'Invalid email format',

  // Success Message Translations
  loggedInSuccess: 'Logged in successfully',

  // Error Message Translations
  loggedOutSuccess: 'Logged Out Successfully',
  internalServerError: 'Internal Server Error',
  usernameOrEmailNotMatch: 'Username/Email or Password does not match',
  userAlreadyExists: 'User already exists',
  userNotExists: 'User does not exists',
  unAuthorized: 'Unauthorized',
  operatorPlayerUnauthenticated: 'Operator player Unauthenticated',

  // Other Translations
  change: 'Change',
  login: 'Login',
  404: '404',
  pageNotFound: "We can't find the page you are looking for!",
  error: 'Error',
  somethingWentWrong: 'Something went wrong!',
  noData: 'No data available.',
  transactions: 'Transactions',
  settings: 'Settings',
  gameRules: 'Game rules',
  loading: 'Loading',
  unauthorizedAccessOrSomethingWentWrong: 'Unauthorized Access Or Something Went Wrong!',
  sessionExpired: 'Session Expired',
  newLoginDetected: 'ERROR: New login detected (Player can only access the game on one device at a time. Please log in again to log out from the previous device)',
  tokenSessionExpired: 'ERROR: Your session is expired, please login again to continue play the game',
  notKnown: 'ERROR: Not Known',
  invalidToken: 'ERROR: Invalid Token. Please login again',
  disconnect: 'Disconnect',

  tournamentName: 'Tournament Name',
  banker: 'Banker',
  buyIn: 'BUY IN',
  betLimit: 'Bet Limit',
  players: 'Players',
  action: 'Action',
  full: 'Full',
  prev: 'Prev',
  next: 'Next',
  auto: 'AUTO',
  win: 'WIN',
  lose: 'LOSE',
  noRecordsFound: 'No Records Found',

  pointLabel: 'The point to be taken to the section',
  minimumAmount: 'Minimum Amount',
  maximumAmount: 'Maximum Amount',
  autoRebuy: 'Auto rebuy (to your buy-in Amount)',
  minimumBuyIn: 'Minimum BuyIn',
  maximumBuyIn: 'Maximum BuyIn',
  ok: 'OK',
  cancel: 'Cancel',
  open: 'Open',
  waiting: 'Waiting',
  running: 'Running',
  hold: 'Hold',
  draw: 'Draw',
  table: 'Table',
  tournament: 'Tournament',
  connectionLostPleaseAgainJoinByLobbyToPlayTournament: 'Oops connection lost! Please again join tournament by lobby to continue game.',
  backToLobby: 'Back to lobby',
  sitHere: 'Sit here',
  submit: 'Submit',
  chatWarning: 'Accounts will be closed if reported for abusive language',
  betAmountMinimum: 'To inject at least',
  betAmountMaximum: 'Max bet amount',
  betModalOkayButtonLabel: 'Okay',
  betModalMaxButtonLabel: 'Max',
  headerBuyIn: 'Buy-In',
  username: 'Username',
  doneActionButton: 'Done',
  drawActionButton: 'Draw',
  goodActionButton: 'Good',
  catchActionButton: 'Catch',
  toRubStain: 'To rub stain',
  takeSeatDueToBankerWarningError: 'As warnings are being called by the units of the current soldiers, please take your turn and sit down.',
  gameInitiatesIn: 'Game initiates in {{time}} seconds',
  yourGameWillStartIn: 'Your game will start in {{time}} seconds',
  welcome: 'Welcome',
  balance: 'Balance',
  above: 'Above',
  low: 'Low',
  medium: 'Medium',
  high: 'High',

  maintenanceMode: 'Maintenance Mode',
  maintenanceModeMessage: 'Game is under maintenance, please join us after some time.',
  roundFailedError: 'This round is been cancelled due to some technical issues, your complete amount will be credited back shortly.',

  //Dealers Applet
  place: 'Place',
  third: '3rd',
  card: 'Card',
  cards: 'Cards',
  declare: 'Declare',
  result: 'Result',
  show: 'Show',
  rake: 'Rake',
  minimumBet: 'Minimum bet',
  amount: 'Amount',
  selected: 'Selected',
  select: 'Select',
  bet: 'Bet',
  save: 'Save',
  enter: 'Enter',
  password: 'Password',
  revenue: 'Revenue',
  details: 'Details',
  player: 'Player',
  reset: 'Reset',
  goBackToTouranmentSettings: 'Go back to tournament settings',

  DEALERS_APPLET: 'DEALERS APPLET',
  HAND_ID: 'HAND ID',
  PLAYER: 'PLAYER',
  USERNAME: 'USERNAME',
  BUY_IN: 'BUY IN',
  BUY_OUT: 'BUY OUT',
  BET: 'BET',
  WIN: 'WIN',
  CARD: 'CARD',
  SCORE: 'SCORE',
  numberOfSeats: 'Number of seats (out of 6)',
  enterBetAmtForPlayer: 'Enter bet amount for player'
}
