import { combineReducers } from '@reduxjs/toolkit'
import authSlice from './slices/auth.slice'
import loaderSlice from './slices/loader.slice'
import footerFiltersSlice from './slices/footerFilters.slice'
import headerSlice from './slices/header.slice'
import shanGameSlice from './slices/shanGame.slice'
import shanSettingsSlice from './slices/shanSettings.slice'
import dealerAppletSlice from './slices/dealerApplet.slice'

const rootReducer = combineReducers({
  auth: authSlice,
  loader: loaderSlice,
  shanSettings: shanSettingsSlice,
  header: headerSlice,
  shanGame: shanGameSlice,
  footerFilters: footerFiltersSlice,
  dealerApplet: dealerAppletSlice
})

export default rootReducer
