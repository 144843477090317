import { createAsyncThunk } from '@reduxjs/toolkit'
import { bankerCatchService, drawCardService, getShanGameTournamentsService, holdCardService, occupyShanGameSeatService, placeBetService } from 'network/services/shanGame.service'

export const fetchShanGameTournamentsThunk = createAsyncThunk('shanGame/tournament-list', async (data, thunkApi) => {
  try {
    const res = await getShanGameTournamentsService(data)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const occupyShanGameSeatThunk = createAsyncThunk('shanGame/occupy-seat', async ({ tournamentId, seatId, buyInAmount, onSuccess, onError, openTakeSeatErrorDueBankerWarningModal }, thunkApi) => {
  try {
    const res = await occupyShanGameSeatService({ tournamentId, seatId, buyInAmount })
    onSuccess()
    return res
  } catch (error) {
    onError()
    if (error[0].name === 'PlayerCannotTakeSeatBankerWarning') {
      openTakeSeatErrorDueBankerWarningModal()
    }
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const placeBetThunk = createAsyncThunk('shanGame/place-bet', async (data, thunkApi) => {
  try {
    const res = await placeBetService(data)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const drawThirdCardThunk = createAsyncThunk('shanGame/draw-card', async (_, thunkApi) => {
  try {
    const res = await drawCardService()
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const holdCardThunk = createAsyncThunk('shanGame/hold-card', async (_, thunkApi) => {
  try {
    const res = await holdCardService()
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const bankerCatchThunk = createAsyncThunk('shanGame/banker-catch', async (_, thunkApi) => {
  try {
    const res = await bankerCatchService()
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})
