import React from 'react'
import './index.scss'
import { useTranslation } from 'react-i18next'

const LoaderPage = () => {
  const { t } = useTranslation()
  return (
    <div className='full-page-loader'>
      <div className='cube-wrapper'>
        <div className='cube-folding'>
          <span className='leaf1' />
          <span className='leaf2' />
          <span className='leaf3' />
          <span className='leaf4' />
        </div>
        <span className='loading' data-name='Loading'>{t('Loading')}</span>
      </div>
    </div>
  )
}

export default LoaderPage
