import { lazy } from 'react'
import { ROUTE_PATHS } from 'constants/index'
const HomePage = lazy(() => import('pages/Home/index'))
const ShanKoeMeeGamePage = lazy(() => import('pages/ShanKoeMeeGame/index'))
const ShanKoeMeeLobbyPage = lazy(() => import('pages/ShanKoeMeeLobby/index'))
const DealersAppletHomePage = lazy(() => import('pages/DealersApplet/DealerHome/index'))
const DealersAppletGamePage = lazy(() => import('pages/DealersApplet/DealerGame/index'))
const ErrorHandlerPage = lazy(() => import('pages/ErrorHandlerPage/index'))

const routesList = [
  {
    path: ROUTE_PATHS.home,
    component: HomePage,
    isPrivate: false,
    isHeader: false,
    isFooter: false,
    headerVariant: 'default',
    footerVariant: 'default'
  },
  {
    path: ROUTE_PATHS.shanKoeMeeLobby,
    component: ShanKoeMeeLobbyPage,
    isPrivate: false,
    isHeader: true,
    isFooter: true,
    headerVariant: 'default',
    footerVariant: 'default'
  },
  {
    path: ROUTE_PATHS.dealerAppletHome,
    component: DealersAppletHomePage,
    isPrivate: false,
    isHeader: false,
    isFooter: false,
    headerVariant: 'default',
    footerVariant: 'default'
  },
  {
    path: ROUTE_PATHS.shanKoeMeeGameTable,
    component: ShanKoeMeeGamePage,
    isPrivate: true,
    isHeader: true,
    isFooter: false,
    headerVariant: 'shanGameInPlay',
    footerVariant: 'shanGameInPlay',
    wantContainerStyle: false
  },
  {
    path: ROUTE_PATHS.dealerAppletGameTable,
    component: DealersAppletGamePage,
    isPrivate: false,
    isHeader: false,
    isFooter: false,
    headerVariant: 'shanGameInPlay',
    footerVariant: 'shanGameInPlay',
    wantContainerStyle: false
  },
  {
    path: ROUTE_PATHS.error,
    component: ErrorHandlerPage,
    isPrivate: false,
    isHeader: false,
    isFooter: false,
    headerVariant: 'default',
    footerVariant: 'default'
  }
]

export default routesList
