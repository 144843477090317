import { createSlice } from '@reduxjs/toolkit'

const defaultLoadersState = {
  headerActiveTabIndex: 0,
  gameHeaderUniqueId: null
}

const headerSlice = createSlice({
  name: 'headerSlice',
  initialState: defaultLoadersState,
  reducers: {
    setHeaderActiveTabIndex: (state, action) => {
      return {
        ...state,
        headerActiveTabIndex: action.payload
      }
    },
    setGameHeaderUniqueId: (state, action) => {
      return {
        ...state,
        gameHeaderUniqueId: action.payload
      }
    }
  }
})

export const { setHeaderActiveTabIndex, setGameHeaderUniqueId } = headerSlice.actions

export default headerSlice.reducer
