import { signOut } from 'helpers/cookie.helpers'
import { openErrorToaster } from 'helpers/toaster.helpers'
// import { getTranslation } from 'helpers/translations.helpers'
import store from 'index'
// import errorMessages from 'network/messages/errorMessages'
import { stopLoader } from 'redux-store/redux/slices/loader.slice'

const EXCLUDE_ERROR_TOASTER_FOR_ERROR_CODE = [3038]

export const errorHandler = (error) => {
  if (error.response.status === 500) {
    store.dispatch(stopLoader(error.response.config.loader))
    return Promise.reject(error.response.data.errors)
  } else if (error.response.status === 401) {
    // Snackbar UnAuthed
    if (error?.response?.data?.errors?.[0]?.errorCode === 3036) { // session changed
      window.history.pushState({ errorMsg: 'sessionExpired', errorCause: 'newLoginDetected', replace: true }, '', `${window.origin}/error`)
      window.location.reload()
    }
    if (error?.response?.data?.errors?.[0]?.errorCode === 3035) { // session changed
      window.history.pushState({ errorMsg: 'sessionExpired', errorCause: 'invalidToken', replace: true }, '', `${window.origin}/error`)
      window.location.reload()
    }
    signOut()
    store.dispatch(stopLoader(error.response.config.loader))
    return Promise.reject(error.response.data.errors)
  } else if (error.response.config?.loader) {
    // Other errors
    store.dispatch(stopLoader(error.response.config.loader))
  }

  // Open Error Toaster
  if (error.response.config?.errorHandlerEnabled && !EXCLUDE_ERROR_TOASTER_FOR_ERROR_CODE.includes(error.response.data.errors[0].errorCode)) {
    const errorMessage = error.response.data.errors[0].description
    openErrorToaster({ message: errorMessage })
  }

  return Promise.reject(error.response.data.errors)
}
