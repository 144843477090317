import React, { lazy } from 'react'
import { Route, Switch } from 'react-router-dom'
import PrivateRoute from './HOC/PrivateRoutes/index'
import PublicRoutes from './HOC/PublicRoutes/index'
import routesList from './routesList'
import { getAuthToken } from 'helpers/cookie.helpers'
const NotFoundComponent = lazy(() => import('components/layout/404/index'))

const RouteRenderer = (route) => {
  const { component: Component, isHeader, isFooter } = route
  const isAuthenticated = getAuthToken()

  return (
    route?.isPrivate
      ? <PrivateRoute isAuthenticated={isAuthenticated} Component={Component} isHeader={isHeader} isFooter={isFooter} {...route} />
      : <PublicRoutes isHeader={isHeader} Component={Component} isFooter={isFooter} {...route} />

  )
}
const CustomRoutes = () => (
  <>
    <Switch>
      {
        routesList.map((route) => (
          <Route path={route.path} key={route.path} exact render={() => RouteRenderer(route)} />
        ))
      }
      <Route component={() => <NotFoundComponent />} />
    </Switch>
  </>
)

export default CustomRoutes
