import { createAsyncThunk } from '@reduxjs/toolkit'
import { getUserInfoService, storeErrorService } from 'network/services/users.service'

export const fetchUserInformation = createAsyncThunk('user/user-detail', async (_, thunkApi) => {
  try {
    const res = await getUserInfoService()
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const storeError = createAsyncThunk('user/store-error', async (data, thunkApi) => {
  try {
    data.data.time = Date()
    const res = await storeErrorService({ key: data.key, data: JSON.stringify(data.data) })
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})
