import React from 'react'
import './index.scss'
import { FOOTER_FILTERS_KEY, LANGUAGES } from 'constants/index'
import { useDispatch, useSelector } from 'react-redux'
import { setFooterFilter } from 'redux-store/redux/slices/footerFilters.slice'
import { CheckedCheckBoxIcon } from 'components/ui-kit/Icons/iconComponents/CheckedCheckBoxIcon'
import { UnCheckedCheckBoxIcon } from 'components/ui-kit/Icons/iconComponents/UnCheckedCheckBoxIcon'
import { useTranslation } from 'react-i18next'
import CustomTypography from 'components/ui-kit/Typography/index'
import { actionSound } from 'utils/audio.utils'
import { getLocalLanguage } from 'helpers/localstorage.helpers'

const DefaultFooter = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const filters = useSelector(state => state.footerFilters)
  const isLanguageEnglish = getLocalLanguage() === LANGUAGES[0].languageCode

  const handleFilters = (filterKey) => {
    actionSound.play()
    dispatch(setFooterFilter(filterKey))
  }

  return (
    <div className='footer-section'>
      <div className='filter-section'>
        <div className='checkbox-container' onClick={() => handleFilters(FOOTER_FILTERS_KEY.OPEN)}>
          {
            filters[FOOTER_FILTERS_KEY.OPEN]
              ? <CheckedCheckBoxIcon />
              : <UnCheckedCheckBoxIcon style={{ marginTop: '4px', marginRight: '2px' }} />
          }
          <CustomTypography fontSizeClass={isLanguageEnglish ? 'fs-1' : ''} fontFamilyClass='montara' fontWeightClass='fw-700'>
            {t('open')}
          </CustomTypography>
        </div>
        <div className='checkbox-container' onClick={() => handleFilters(FOOTER_FILTERS_KEY.WAITING)}>
          {
            filters[FOOTER_FILTERS_KEY.WAITING]
              ? <CheckedCheckBoxIcon />
              : <UnCheckedCheckBoxIcon style={{ marginTop: '4px', marginRight: '2px' }} />
          }
          <CustomTypography fontSizeClass={isLanguageEnglish ? 'fs-1' : ''} fontFamilyClass='montara' fontWeightClass='fw-700'>
            {t('waiting')}
          </CustomTypography>
        </div>
        <div className='checkbox-container' onClick={() => handleFilters(FOOTER_FILTERS_KEY.RUNNING)}>
          {
            filters[FOOTER_FILTERS_KEY.RUNNING]
              ? <CheckedCheckBoxIcon />
              : <UnCheckedCheckBoxIcon style={{ marginTop: '4px', marginRight: '2px' }} />
          }
          <CustomTypography fontSizeClass={isLanguageEnglish ? 'fs-1' : ''} fontFamilyClass='montara' fontWeightClass='fw-700'>
            {t('running')}
          </CustomTypography>

        </div>
        <div className='checkbox-container' onClick={() => handleFilters(FOOTER_FILTERS_KEY.FULL)}>
          {
            filters[FOOTER_FILTERS_KEY.FULL]
              ? <CheckedCheckBoxIcon />
              : <UnCheckedCheckBoxIcon style={{ marginTop: '4px', marginRight: '2px' }} />
          }
          <CustomTypography fontSizeClass={isLanguageEnglish ? 'fs-1' : ''} fontFamilyClass='montara' fontWeightClass='fw-700'>
            {t('full')}
          </CustomTypography>

        </div>
      </div>
    </div>
  )
}

export default DefaultFooter
