import React from 'react'
import './index.scss'
import PropTypes from 'prop-types'

const CustomTypography = ({
  children, fontSizeClass, fontWeightClass, textAlignClass, otherStyleClasses,
  component, onClick, fontFamilyClass, textTransform, ...otherProps
}) => {
  const TypographyTag = component
  return (
    <TypographyTag className={`${fontSizeClass} ${fontWeightClass} ${textAlignClass} ${otherStyleClasses} ${fontFamilyClass} ${textTransform}`} {...otherProps} onClick={onClick}>
      {children}
    </TypographyTag>
  )
}

export default CustomTypography

CustomTypography.defaultProps = {
  children: 'Label',
  fontSizeClass: 'fs-0x8',
  fontWeightClass: 'fw-500',
  textAlignClass: '',
  otherStyleClasses: '',
  component: 'span',
  onClick: () => ({}),
  fontFamilyClass: 'arial'
}

CustomTypography.propTypes = {
  children: PropTypes.any.isRequired,
  fontSizeClass: PropTypes.oneOf(['fs-0x5', 'fs-0x6', 'fs-0x7', 'fs-0x8', 'fs-0x9', 'fs-1', 'fs-1x1', 'fs-1x2', 'fs-1x3', 'fs-1x4', 'fs-1x5', 'fs-1x6', 'fs-1x7', 'fs-1x8', 'fs-1x9', 'fs-2', '', 'fs-1x375']),
  fontWeightClass: PropTypes.oneOf(['fw-400', 'fw-500', 'fw-600', 'fw-700', 'fw-800', 'fw-900', '']),
  textAlignClass: PropTypes.oneOf(['text-center', 'text-left', 'text-right', '']),
  otherStyleClasses: PropTypes.string,
  component: PropTypes.oneOf(['span', 'div', 'p']),
  onClick: PropTypes.func,
  fontFamilyClass: PropTypes.oneOf(['arial', 'montara', 'arial-condensed-bold']),
  textTransform: PropTypes.oneOf(['text-lowercase', 'text-uppercase', 'text-capitalize'])
}
