import React from 'react'
import './index.scss'
import PropTypes from 'prop-types'
import DefaultHeader from './DefaultHeader/index'
import ShanGameInPlayHeader from './ShanGameInPlayHeader/index'

const Header = ({ variant }) => {
  switch (variant) {
    case 'shanGameInPlay':
      return <ShanGameInPlayHeader />

    default:
      return <DefaultHeader />
  }
}

export default Header

Header.defaultProps = {
  variant: 'default'
}

Header.propTypes = {
  variant: PropTypes.oneOf(['default', 'shanGameInPlay'])
}
