import React from 'react'
import ReactDOM from 'react-dom'
import './assets/scss/index.scss'
import App from './App'
import './translation/index'
import store from 'redux-store/store'
import { appRoot } from './assets/dom/domNodes'
import { Provider } from 'react-redux'

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  appRoot
)

export default store
