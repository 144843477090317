import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './index.scss'
import CustomTabs from 'components/ui-kit/Tabs/index'
import CustomTypography from 'components/ui-kit/Typography/index'
import { setHeaderActiveTabIndex } from 'redux-store/redux/slices/header.slice'
import { BackArrowIcon } from 'components/ui-kit/Icons/iconComponents/BackArrowIcon'
import { fetchGameSettingsService } from 'redux-store/thunk/shanSettings.thunk'
import { useTranslation } from 'react-i18next'
import { actionSound } from 'utils/audio.utils'

const DefaultHeader = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { headerActiveTabIndex } = useSelector(state => state.header)
  const { userInfo } = useSelector(state => state.auth)
  const { settings } = useSelector(state => state.shanSettings)

  const handleTabClick = (tabIndex) => {
    actionSound.play()
    dispatch(setHeaderActiveTabIndex(tabIndex))
  }

  useEffect(() => {
    userInfo && dispatch(fetchGameSettingsService())
  }, [userInfo])

  return (
    <div className='row gx-0'>
      <div className='col'>
        <CustomTabs tabsDataList={settings?.tournamentRange} handleTabClick={handleTabClick} activeTabIndex={headerActiveTabIndex} />
      </div>
      <div className='col-auto'>
        <div className='user-info-section'>
          <div className='text-right'>
            <div className='info-label-style '>
              <CustomTypography fontSizeClass='fs-1' fontFamilyClass='montara' fontWeightClass='fw-700' otherStyleClasses='info-text-color'>
                {t('welcome')} {userInfo?.user?.userName || 'User'}
              </CustomTypography>

            </div>
            <div className='info-label-style '>
              <CustomTypography fontSizeClass='fs-1' fontFamilyClass='montara' fontWeightClass='fw-700' otherStyleClasses='info-text-color'>
                {t('balance')}: {userInfo?.wallet?.balance?.toFixed(2) || '0'}
              </CustomTypography>
            </div>
          </div>
          <div>
            <BackArrowIcon onClick={() => {
              actionSound.play()
              // window.location.href = window.sessionStorage.getItem('returnUrl')
            }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default DefaultHeader
