import { createSlice } from '@reduxjs/toolkit'
import { fetchBankerCatchResult } from 'redux-store/thunk/dealerApplet.thunk'

const initialState = {
  playerData: null
}

const dealerAppletSlice = createSlice({
  name: 'dealerAppletSlice',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchBankerCatchResult.fulfilled, (state, action) => {
        return {
          ...state,
          playerData: action.payload
        }
      })
  }
})

export default dealerAppletSlice.reducer
