import React from 'react'
import PropTypes from 'prop-types'
import { ScaleLoader } from 'react-spinners'

const Loader = ({ color }) => {
  return (
    <ScaleLoader
      color={color}
      size={25}
      aria-label='Loading Spinner'
      data-testid='loader'
    />
  )
}

export default Loader

Loader.defaultProps = {
  color: '#fdb951'
}

Loader.propTypes = {
  color: PropTypes.string
}
