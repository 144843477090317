import { createSlice } from '@reduxjs/toolkit'
import { getPayloadFromToken } from 'helpers/cookie.helpers'
import { gameCloseThunk, userLoginThunk } from 'redux-store/thunk/auth.thunk'
import { fetchUserInformation } from 'redux-store/thunk/user.thunk'

const defaultAuthState = {
  userLoggedIn: false,
  userInfo: null,
  profileDetails: null,
  currencyId: null,
  currencyCode: null
}

const authSlice = createSlice({
  name: 'authSlice',
  initialState: defaultAuthState,
  reducers: {
    setUserLoggedIn: state => ({
      ...state,
      userLoggedIn: !state.userLoggedIn
    }),
    setUserInfo: (state, { payload }) => ({
      ...state,
      userInfo: {
        ...state.userInfo,
        wallet: payload
      }
    }),
    updateWalletBalance: (state, { payload }) => ({
      ...state,
      userInfo: {
        ...state?.userInfo,
        wallet: { ...state?.userInfo?.wallet, balance: payload }
      }
    })
  },
  extraReducers: (builder) => {
    builder
      .addCase(userLoginThunk.fulfilled, (state, action) => {
        const { user, wallet, accessToken } = action.payload
        const payload = getPayloadFromToken(accessToken)
        const currencyId = payload?.currencyId
        const currencyCode = payload?.currencyCode
        return {
          ...state,
          currencyId,
          currencyCode,
          userInfo: { user, wallet }
        }
      })
      .addCase(fetchUserInformation.fulfilled, (state, action) => {
        return {
          ...state,
          profileDetails: action.payload
        }
      })
      .addCase(gameCloseThunk.fulfilled, (state, action) => {
        return defaultAuthState
      })
  }
})

export const { setUserInfo, setUserLoggedIn, updateWalletBalance } = authSlice.actions

export default authSlice.reducer
