import { TOKEN } from 'constants/index'
// import Cookies from 'universal-cookie'
// const cookies = new Cookies()

// // 24 hour * 60 minutes of every hour
// const COOKIE_EXPIRE_MIN = 24 * 60

export const getAuthToken = () => {
  // return cookies.get()
  return sessionStorage.getItem(TOKEN)
}

export const setAuthToken = (authAccessToken) => {
  sessionStorage.setItem(TOKEN, authAccessToken)
  // cookies.set(TOKEN, authAccessToken, {
  //   path: '/',
  //   expires: new Date((Date.now() + COOKIE_EXPIRE_MIN * 60 * 1000))
  // })
}

export const removeAuthToken = () => {
  // cookies.remove(TOKEN, { path: '/' })
  sessionStorage.removeItem(TOKEN)
}

export const signIn = ({ token }) => {
  setAuthToken(token)
}

export const signOut = () => {
  removeAuthToken()
}

export const getPayloadFromToken = (token) => {
  if (token) {
    return JSON.parse(atob(token.split('.')[1]))
  }
}

export const setDealerAppletAuthToken = (value) => {
  sessionStorage.setItem('dealerAppletToken', JSON.stringify(value))
}

export const getDealerAppletToken = () => {
  const item = sessionStorage.getItem('dealerAppletToken')
  return item ? JSON.parse(item) : null
}
