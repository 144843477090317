import { openSuccessToaster } from 'helpers/toaster.helpers'
import { getTranslation } from 'helpers/translations.helpers'
import { stopLoader } from 'redux-store/redux/slices/loader.slice'
import store from 'index'

export const responseSuccessHandler = (response) => {
  const { responseType = 'json', successMessage, loader } = response.config || {}
  if (responseType === 'blob') {
    return { file: response.data, headers: response.headers }
  }
  // // Loader logic to remove loader
  if (loader) {
    store.dispatch(stopLoader(loader))
  }
  // Open Success Toaster
  successMessage && openSuccessToaster({ message: getTranslation(successMessage) })
  return response.data.data
}
