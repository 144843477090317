import React from 'react'
import './index.scss'
import PropTypes from 'prop-types'
import DefaultFooter from './components/DefaultFooter/index'
import GameInPlayFooter from './components/GameInPlayFooter/index'

const Footer = ({ variant }) => {
  switch (variant) {
    case 'default':
      return <DefaultFooter />

    case 'gameInPlay':
      return <GameInPlayFooter />

    default:
      return <DefaultFooter />
  }
}

export default Footer

Footer.defaultProps = {
  variant: 'default'
}

Footer.propTypes = {
  variant: PropTypes.oneOf(['default', 'gameInPlay'])
}
