import CustomTypography from 'components/ui-kit/Typography/index'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'

class ErrorBoundary extends Component {
  constructor (props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError (_error) {
    return { hasError: true }
  }

  render () {
    const { t } = this.props
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <CustomTypography fontSizeClass='fs-2' fontWeightClass='fw-700' textAlignClass='text-center' component='div' otherStyleClasses='mt-50'>
          {t('somethingWentWrong')}
        </CustomTypography>
      )
    }

    return this.props.children
  }
}

export default withTranslation()(ErrorBoundary)
