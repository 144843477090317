import { createSlice } from '@reduxjs/toolkit'
import { drawThirdCardThunk, fetchShanGameTournamentsThunk, occupyShanGameSeatThunk } from 'redux-store/thunk/shanGame.thunk'

const defaultAuthState = {
  tournamentDetails: null,
  tournaments: null,
  authenticatedUserRoundHandDetails: null,
  drawThirdCard: false,
  holdCards: false,
  isGameLeaveTableBtnEnabled: true,
  showCardsAfterDragScene: false
}

const shanGameSlice = createSlice({
  name: 'shanGameSlice',
  initialState: defaultAuthState,
  reducers: {
    setDrawThirdCard: (state, action) => {
      return {
        ...state,
        drawThirdCard: action.payload
      }
    },
    setHoldCards: (state, action) => {
      return {
        ...state,
        holdCards: action.payload
      }
    },
    setBankerCatch: (state, action) => {
      return {
        ...state,
        bankerCatch: action.payload
      }
    },
    setTournaments: (state, action) => {
      return {
        ...state,
        tournaments: { ...state.tournaments, rows: action.payload }
      }
    },
    setTournamentsEmpty: (state) => {
      return {
        ...state,
        tournaments: {
          count: 0,
          rows: []
        }
      }
    },
    setTournamentDetails: (state, action) => {
      return {
        ...state,
        tournamentDetails: action.payload
      }
    },
    setIsGameLeaveTableBtnEnabled: (state, action) => {
      return {
        ...state,
        isGameLeaveTableBtnEnabled: action.payload
      }
    },
    setShowCardsAfterDragScene: (state, action) => {
      return {
        ...state,
        showCardsAfterDragScene: action.payload
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchShanGameTournamentsThunk.fulfilled, (state, action) => {
        return {
          ...state,
          tournaments: action.payload
        }
      })
      .addCase(occupyShanGameSeatThunk.fulfilled, (state, action) => {
        return { ...state }
      })
      .addCase(drawThirdCardThunk.fulfilled, (state, action) => {
        return {
          ...state,
          authenticatedUserRoundHandDetails: action.payload
        }
      })
  }
})

export const { setDrawThirdCard, setHoldCards, setBankerCatch, setTournaments, setTournamentDetails, setTournamentsEmpty, setIsGameLeaveTableBtnEnabled, setShowCardsAfterDragScene } = shanGameSlice.actions
export default shanGameSlice.reducer
