const errorMessages = {
  internalServerError: 'internalServerError',
  unAuthorized: 'unAuthorized',
  usernameOrEmailNotMatch: 'usernameOrEmailNotMatch',
  userAlreadyExists: 'userAlreadyExists',
  userNotExists: 'userNotExists',
  operatorPlayerUnauthenticated: 'operatorPlayerUnauthenticated'
}

export default errorMessages
