import { createAsyncThunk } from '@reduxjs/toolkit'
import { getBankerCatchResultService } from 'network/services/dealerApplet.service'

export const fetchBankerCatchResult = createAsyncThunk('dealer-applet/banker-catch-result', async (data, thunkApi) => {
  try {
    const { payload, update } = data
    const res = await getBankerCatchResultService(payload)
    update(res)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})
