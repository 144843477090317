import React from 'react'
import MainLayout from 'components/layout/MainLayout/index'

const PublicRoute = ({ Component, isHeader, isFooter, headerVariant, footerVariant, ...otherProps }) => {
  return (
    <MainLayout isHeader={isHeader} isFooter={isFooter} headerVariant={headerVariant} footerVariant={footerVariant} {...otherProps}>
      <Component />
    </MainLayout>
  )
}

export default PublicRoute
