import axiosInstanceService from 'network/apis/index'
import { microServices } from 'network/apis/microservice/index'
import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'constants/index'

export const getShanGameTournamentsService = (params) => {
  return axiosInstanceService(METHOD_TYPES.get, 'tournaments/get', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params
  })
}

export const occupyShanGameSeatService = (data) => {
  return axiosInstanceService(METHOD_TYPES.post, 'shan-game/take-seat', data, {
    server: microServices.USER_URL,
    loader: null
  })
}

export const placeBetService = (data) => {
  return axiosInstanceService(METHOD_TYPES.post, 'shan-game/place-bet', data, {
    server: microServices.USER_URL,
    loader: null,
    errorHandlerEnabled: false
  })
}

export const drawCardService = () => {
  return axiosInstanceService(METHOD_TYPES.post, 'shan-game/draw-card', {}, {
    server: microServices.USER_URL,
    loader: null,
    errorHandlerEnabled: false
  })
}

export const holdCardService = () => {
  return axiosInstanceService(METHOD_TYPES.post, 'shan-game/hold-card', {}, {
    server: microServices.USER_URL,
    loader: null,
    errorHandlerEnabled: false
  })
}

export const bankerCatchService = () => {
  return axiosInstanceService(METHOD_TYPES.post, 'shan-game/banker-catch', {}, {
    server: microServices.USER_URL,
    loader: null,
    errorHandlerEnabled: false
  })
}
