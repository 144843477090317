import MainLayout from 'components/layout/MainLayout/index'
import React from 'react'
import { Redirect } from 'react-router-dom'

const PrivateRoute = ({ Component, isAuthenticated, isHeader, isFooter, headerVariant, footerVariant, ...otherProps }) => {
  return isAuthenticated
    ? (
      <MainLayout isHeader={isHeader} isFooter={isFooter} headerVariant={headerVariant} footerVariant={footerVariant} {...otherProps}>
        <Component />
      </MainLayout>
      )
    : (
      <Redirect to={{ pathname: '/' }} />
      )
}

export default PrivateRoute
